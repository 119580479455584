



















































import Button from '@/components/button.vue';
import Form from '@/components/form.vue';
import OfferHeader from '@/components/offer-header.vue';
import ProductBullets from '@/components/product-bullets.vue';
import ReadMore from '@/components/read-more.vue';
import ScrollButton from '@/components/scroll-button.vue';
import {Component} from '@/decorators';
import {
    PortalSettings,
    PublicSubscriptionPortalOffer,
    PublicVisit,
    SubscriptionFieldValue
} from '@/model/backend/campaign-api';
import {FormLayout} from '@/model/frontend/form';
import {FormDataType} from '@/model/frontend/form-input';
import Vue from 'vue';
import {Action, Getter} from 'vuex-class';

@Component({
    components: {ScrollButton, OfferHeader, Button, Form, ProductBullets, ReadMore}
})
export default class FormView extends Vue {
    @Getter
    private settings: PortalSettings;

    @Getter
    private offer: PublicSubscriptionPortalOffer;

    @Getter
    private visit: PublicVisit;

    @Action
    private submitSubscription: (fields: SubscriptionFieldValue[]) => Promise<void>;

    private formLayout: FormLayout = [
        [
            {
                placeholder: '',
                dataType: FormDataType.SALUTATION,
                width: 12,
                key: 'SALUTATION',
                autocomplete: 'sex'
            }
        ],
        [
            {
                placeholder: 'Vorname',
                dataType: FormDataType.NAME,
                width: 6,
                key: 'FIRST_NAME',
                autocomplete: 'given-name'
            },
            {
                placeholder: 'Nachname',
                dataType: FormDataType.NAME,
                width: 6,
                key: 'LAST_NAME',
                grow: true,
                autocomplete: 'family-name'
            }
        ],
        [
            {
                placeholder: 'Straße',
                dataType: FormDataType.STREET,
                width: 8,
                key: 'STREET',
                grow: true,
                autocomplete: 'street'
            },
            {
                placeholder: 'Nr.',
                dataType: FormDataType.STREET_NUMBER,
                width: 4,
                key: 'STREET_NUMBER',
                autocomplete: 'street-number'
            }
        ],
        [
            {
                placeholder: 'Adresszusatz',
                dataType: FormDataType.TEXT,
                width: 6,
                key: 'ADDRESS_SUPPLEMENTS',
                grow: true,
            },
            {
                placeholder: 'Ablageort',
                dataType: FormDataType.TEXT,
                width: 6,
                key: 'FILING_LOCATION',
                grow: true
            }
        ],
        [
            {
                placeholder: 'PLZ',
                dataType: FormDataType.ZIP_CODE,
                width: 4,
                key: 'ZIP_CODE',
                autocomplete: 'postal-code'
            },
            {
                placeholder: 'Stadt',
                dataType: FormDataType.CITY,
                width: 8,
                key: 'CITY',
                autocomplete: 'city'
            }
        ],
        [
            {
                placeholder: 'E-Mail',
                dataType: FormDataType.EMAIL,
                width: 12,
                key: 'EMAIL',
                autocomplete: 'email'
            }
        ],
        [
            {
                placeholder: 'Telefon',
                dataType: FormDataType.PHONE,
                width: 12,
                key: 'PHONE',
                grow: true,
                autocomplete: 'tel'
            }
        ],
        [
            {
                placeholder: 'Geburtstag',
                dataType: FormDataType.BIRTHDAY,
                width: 6,
                key: 'BIRTHDAY',
                grow: true,
                autocomplete: 'bday'
            },
            {
                placeholder: 'Lieferdatum',
                dataType: FormDataType.DELIVERY_DATE,
                width: 6,
                key: 'DELIVERYDAY',
                grow: true
            }
        ],
        [
            {
                placeholder: 'Nationalität',
                dataType: FormDataType.COUNTRY,
                width: 12,
                key: 'NATIONALITY',
                autocomplete: 'country-name'
            }
        ],
        [
            {
                placeholder: 'Wohnsitz',
                dataType: FormDataType.RESIDENCE,
                width: 6,
                key: 'RESIDENCE',
                grow: true,
                autocomplete: 'locality'
            },
            {
                placeholder: 'Geburtsort',
                dataType: FormDataType.COUNTRY,
                width: 6,
                key: 'BIRTHPLACE',
                grow: true,
                autocomplete: 'birthplace'
            }
        ],
        [
            {
                placeholder: 'Kontoinhaber',
                dataType: FormDataType.NAME,
                width: 12,
                key: 'DEPOSITOR'
            }
        ],
        [
            {
                placeholder: 'IBAN',
                dataType: FormDataType.IBAN,
                width: 12,
                key: 'IBAN'
            }
        ],
        [
            {
                placeholder: '',
                dataType: FormDataType.CUSTOM_FIELD,
                width: 12,
                key: 'CUSTOM_FIELD_1'
            }
        ],
        [
            {
                placeholder: '',
                dataType: FormDataType.READ_MEDIUM,
                width: 12,
                key: 'READ_MEDIUM'
            }
        ],
        [
            {
                placeholder: '',
                dataType: FormDataType.CHECKBOX,
                width: 12,
                key: 'CONFIRM_BUSINESS_TERMS'
            }
        ],
        [
            {
                placeholder: '',
                dataType: FormDataType.CHECKBOX,
                width: 12,
                key: 'CONFIRM_DIGITAL'
            }
        ],
        [
            {
                placeholder: '',
                dataType: FormDataType.CHECKBOX,
                width: 12,
                key: 'CONFIRM_NEWSLETTER'
            }
        ],
        [
            {
                placeholder: '',
                dataType: FormDataType.LABEL,
                width: 12,
                key: 'PRE_PERMISSION_TEXT'
            }
        ],
        [
            {
                placeholder: '',
                dataType: FormDataType.CHECKBOX,
                width: 12,
                key: 'PERMISSION_PHONE'
            }
        ],
        [
            {
                placeholder: '',
                dataType: FormDataType.CHECKBOX,
                width: 12,
                key: 'PERMISSION_EMAIL'
            }
        ],
        [
            {
                placeholder: '',
                dataType: FormDataType.LABEL,
                width: 12,
                key: 'POST_PERMISSION_TEXT'
            }
        ]
    ];

    private submit() {
        if (!(this.$refs.form as Form).checkValidation()) {
            return;
        }
        this.$ga.event(`Vouch_Abo_${this.offer.name}`, 'Form_Submit', null, null);
        let formData = (this.$refs.form as Form).formData;
        this.submitSubscription(formData).then(this.next);
    }

    private next() {
        this.$router.push({
            name: 'upgrade-view',
            params: this.$route.params
        });
    }

    private get oldPortalUrl(): string {
        return `/portal#/${this.$route.params.visitId}/subscription/${this.$route.params.offerId}`;
    }
}
