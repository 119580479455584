var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      staticClass: "form",
      attrs: { id: "goToInputForm" },
      on: {
        submit: function($event) {
          $event.preventDefault()
        }
      }
    },
    [
      _c("v-dialog"),
      _vm.offer.form.prefilledFieldsBehaviour === "MUTABLE_ON_BUTTON_PRESS" &&
      _vm.disabledFields.length
        ? _c("div", { staticClass: "mutable-button-container" }, [
            _c("span", { staticClass: "info-text" }, [
              _vm._v(
                "Folgende Adressdaten haben wir für Sie bereits eingetragen. Nicht korrekt?"
              )
            ]),
            _c(
              "span",
              {
                staticClass: "mutable-button",
                on: {
                  click: function($event) {
                    _vm.disabledFields = []
                  }
                }
              },
              [_vm._v("Adressdaten bearbeiten")]
            )
          ])
        : _vm._e(),
      _vm._l(_vm.formLayout, function(row, rowIndex) {
        return _c(
          "div",
          { staticClass: "form-row" },
          [
            _vm._l(row, function(item, itemIndex) {
              return _vm.formConfiguration.hasOwnProperty(item.key)
                ? [
                    item.dataType === "label"
                      ? [
                          _c("span", {
                            staticClass: "label col",
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.formConfiguration[item.key].description
                              )
                            }
                          })
                        ]
                      : item.dataType === "iban"
                      ? [
                          _c("iban-input", {
                            class: ["col-" + _vm.getWidth(rowIndex, itemIndex)],
                            attrs: {
                              required:
                                _vm.isForceRequired(item.key) ||
                                _vm.formConfiguration[item.key].mandatory,
                              value: _vm.getValue(item.key)
                            },
                            on: {
                              input: function($event) {
                                return _vm.setValue(item.key, $event)
                              }
                            }
                          })
                        ]
                      : _c("FormInput", {
                          key: item.key,
                          ref: "input" + item.key,
                          refInFor: true,
                          class: ["col-" + _vm.getWidth(rowIndex, itemIndex)],
                          attrs: {
                            customProperties:
                              _vm.formConfiguration[item.key]
                                .customProperties || {},
                            cutFrom:
                              _vm.formConfiguration[item.key].cutFrom || 0,
                            dataType: item.dataType,
                            description:
                              _vm.formConfiguration[item.key].description,
                            disabled:
                              _vm.disabledFields.indexOf(item.key) !== -1,
                            placeholder: _vm.getPlaceholder(item),
                            required:
                              _vm.isForceRequired(item.key) ||
                              _vm.formConfiguration[item.key].mandatory,
                            value: _vm.getValue(item.key),
                            fixedPlaceholder: "",
                            autocomplete: item.autocomplete
                          },
                          on: {
                            input: function($event) {
                              return _vm.setValue(item.key, $event)
                            },
                            setConfirmMandatory: _vm.setConfirmMandatory
                          }
                        })
                  ]
                : _vm._e()
            })
          ],
          2
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }