var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "offer-header" }, [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-auto mr-auto" }, [
        _c("h1", {
          staticClass: "offer-title",
          domProps: { innerHTML: _vm._s(_vm.offer.subtitle) }
        })
      ]),
      _vm.offer.logoImage
        ? _c("div", { staticClass: "col-auto logo-image d-none d-md-block" }, [
            _c("img", {
              staticClass: "logo-image",
              attrs: { src: _vm.offer.logoImage, alt: "Logo Image" }
            })
          ])
        : _vm._e()
    ]),
    _vm.settings.showEmotion || _vm.settings.showProductImage
      ? _c("div", { staticClass: "row image-row d-none d-md-block" }, [
          _c("div", { staticClass: "col-12 emotion-image" }, [
            _vm.settings.showEmotion
              ? _c("img", {
                  attrs: {
                    src: _vm.offer.form.emotionImage,
                    alt: "Emotion Image"
                  }
                })
              : _vm._e()
          ]),
          _vm.offer.form.productImage
            ? _c("div", { staticClass: "col-12 product-image" }, [
                _vm.settings.showProductImage
                  ? _c("img", {
                      attrs: {
                        src: _vm.offer.form.productImage,
                        alt: "Product Image"
                      }
                    })
                  : _vm._e()
              ])
            : _vm._e()
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }