






import Button from '@/components/button.vue';
import {Component, Prop} from '@/decorators';
import {ButtonObject} from '@/model/backend/campaign-api';
import {scrollAnimated} from '@/utils/scroll';
import Vue from 'vue';

@Component({
    components: {Button}
})
export default class ScrollButton extends Vue {

    @Prop({required: true})
    private button: ButtonObject | null;

    @Prop({required: true})
    private scrollTo: string;

    private scrollToForm() {
        scrollAnimated(window.document.getElementById(this.scrollTo)!, 0, true);

        this.$emit('click');
    }
}
