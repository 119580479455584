





















import {Component} from '@/decorators';
import {PortalSettings, PublicSubscriptionPortalOffer} from '@/model/backend/campaign-api';
import Vue from 'vue';
import {Getter} from 'vuex-class';

@Component({})
export default class OfferHeader extends Vue {
    @Getter
    private settings: PortalSettings;

    @Getter
    private offer: PublicSubscriptionPortalOffer;
}
