var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "form-view container mt-4" },
    [
      _c("offer-header"),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-md-6 mb-sm-4" },
          [
            _vm.settings.showOfferImage
              ? _c("div", { staticClass: "row image-row" }, [
                  _c("div", { staticClass: "col-12" }, [
                    _c("img", {
                      staticStyle: { "max-width": "100%" },
                      attrs: { src: _vm.offer.offerImage, alt: "Offer Image" }
                    })
                  ])
                ])
              : _vm._e(),
            _vm.offer.form.details.productTitle
              ? _c("h2", { staticClass: "offer-title d-none d-md-block" }, [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.offer.form.details.productTitle)
                  )
                ])
              : _vm._e(),
            _c("product-bullets", {
              attrs: {
                "bullet-points": _vm.offer.form.details.bulletPoints,
                image: _vm.offer.form.productImage,
                optionalMobile: false
              }
            }),
            _vm.settings.showScrollButton
              ? _c("scroll-button", {
                  attrs: {
                    button: _vm.offer.form.orderButton,
                    "scroll-to": "form"
                  },
                  on: { click: _vm.submit }
                })
              : _vm._e(),
            _vm.offer.form.details.teaserText
              ? _c("p", {
                  staticClass: "offer-teaser d-none d-md-block",
                  domProps: {
                    innerHTML: _vm._s(_vm.offer.form.details.teaserText)
                  }
                })
              : _vm._e(),
            _vm.offer.form.details.agreements
              ? _c(
                  "div",
                  { staticClass: "offer-body d-none d-md-block" },
                  [
                    _c("ReadMore", {
                      attrs: {
                        value: _vm.offer.form.details.agreements,
                        cutFrom: 15
                      }
                    })
                  ],
                  1
                )
              : _vm._e()
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-md-6", attrs: { id: "form" } },
          [
            _c("h2", { staticClass: "form-headline" }, [
              _vm._v(_vm._s(_vm.offer.form.formHeadline))
            ]),
            _c("Form", {
              ref: "form",
              attrs: {
                formConfiguration: _vm.offer.form.formFields,
                formLayout: _vm.formLayout
              }
            }),
            _vm.offer.form.details.agreements
              ? _c(
                  "div",
                  {
                    staticClass:
                      "agreements-form d-xs-block d-sm-block d-md-none"
                  },
                  [
                    _c("ReadMore", {
                      attrs: {
                        value: _vm.offer.form.details.agreements,
                        cutFrom: 15
                      }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "Button",
              {
                staticClass: "order-button mt-2 mb-2",
                attrs: {
                  id: "orderButton",
                  data: _vm.offer.form.orderButton,
                  type: "custom",
                  fill: ""
                },
                on: { click: _vm.submit }
              },
              [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.offer.buttonText) +
                    "\n            "
                )
              ]
            )
          ],
          1
        )
      ]),
      _c("div", { staticClass: "row mt-4" }, [
        _c(
          "div",
          { staticClass: "col-12" },
          [
            _c(
              "router-link",
              {
                staticClass: "back-to-overview",
                attrs: {
                  to: {
                    name: "external-landing-page-view",
                    params: {
                      visitId: _vm.$route.params.visitId,
                      snippetId: this.visit.snippetId,
                      load: "0"
                    }
                  }
                }
              },
              [_vm._v("\n                « Zurück zur Auswahl\n            ")]
            )
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }