








































import FormInput from '@/components/form-input.vue';
import IbanInput from '@/components/iban-input.vue';
import {Component, Prop} from '@/decorators';
import {PublicSubscriptionPortalOffer, PublicVisit, SubscriptionFormField} from '@/model/backend/campaign-api';
import {FormConfiguration, FormData, FormInputConfiguration, FormLayout} from '@/model/frontend/form';
import Vue from 'vue';
import {Getter} from 'vuex-class';

@Component({
    components: {IbanInput, FormInput}
})
export default class Form extends Vue {
    public formData: FormData = [];

    @Prop([Array])
    private formLayout: FormLayout;

    @Prop
    private formConfiguration: FormConfiguration;

    @Getter
    private visit: PublicVisit;

    @Getter
    private offer: PublicSubscriptionPortalOffer;

    private disabledFields: SubscriptionFormField[] = [];

    private formKeyIndexMap: { [key: string]: number } = {};

    public mounted() {
        if (this.offer.form.prefilledFieldsBehaviour !== 'MUTABLE') {
            if (this.visit.contact.salutation) {
                this.disabledFields.push('SALUTATION');
            }
            if (this.visit.contact.firstName) {
                this.disabledFields.push('FIRST_NAME');
            }
            if (this.visit.contact.lastName) {
                this.disabledFields.push('LAST_NAME');
            }
            if (this.visit.contact.street) {
                this.disabledFields.push('STREET');
            }
            if (this.visit.contact.streetNumber) {
                this.disabledFields.push('STREET_NUMBER');
            }
            if (this.visit.contact.zipCode) {
                this.disabledFields.push('ZIP_CODE');
            }
            if (this.visit.contact.city) {
                this.disabledFields.push('CITY');
            }
            if (this.visit.contact.email) {
                this.disabledFields.push('EMAIL');
            }
            if (this.visit.contact.phone) {
                this.disabledFields.push('PHONE');
            }
            if (this.visit.contact.dateOfBirth) {
                this.disabledFields.push('BIRTHDAY');
            }
        }
        this.setValue('SALUTATION', this.visit.contact.salutation);
        this.setValue('FIRST_NAME', this.visit.contact.firstName);
        this.setValue('LAST_NAME', this.visit.contact.lastName);
        this.setValue('STREET', this.visit.contact.street);
        this.setValue('STREET_NUMBER', this.visit.contact.streetNumber);
        this.setValue('ZIP_CODE', this.visit.contact.zipCode);
        this.setValue('CITY', this.visit.contact.city);
        this.setValue('EMAIL', this.visit.contact.email);
        this.setValue('PHONE', this.visit.contact.phone);
        this.setValue('BIRTHDAY', this.visit.contact.dateOfBirth);
        this.setValue('CONFIRM_BUSINESS_TERMS', false);
        this.setValue('CONFIRM_DIGITAL', false);
        this.setValue('CONFIRM_NEWSLETTER', false);
        this.setValue('PERMISSION_PHONE', false);
        this.setValue('PERMISSION_EMAIL', false);
        if (this.visit.contact.firstName && this.visit.contact.lastName) {
            this.setValue('INVOICE', `${this.visit.contact.firstName} ${this.visit.contact.lastName}`);
        }
    }

    public checkValidation(): boolean {
        let valid: boolean = true;
        for (let row of this.formLayout) {
            for (let item of row) {
                let input = this.$refs[`input${item.key}`] as [FormInput | IbanInput] | undefined;
                if (input && input.length) {
                    if (!input[0].checkValidation(valid)) {
                        valid = false;
                    }
                }
            }
        }
        return valid;
    }

    public setConfirmMandatory(mand) {
        if (!!this.formConfiguration.CONFIRM_BUSINESS_TERMS) {
            this.formConfiguration.CONFIRM_BUSINESS_TERMS.mandatory = mand;
        }
    }

    private getWidth(rowIndex: number, itemIndex: number) {
        let row = this.formLayout[rowIndex];
        let item = row[itemIndex];
        if (item.grow !== true) {
            return item.width;
        }
        let widthToFill = 0;
        for (let current of row) {
            if (!this.formConfiguration.hasOwnProperty(current.key)) {
                widthToFill += current.width;
            }
        }
        return item.width + widthToFill;
    }

    private getValue(key: SubscriptionFormField): string {
        if (!this.formKeyIndexMap.hasOwnProperty(key)) {
            this.formKeyIndexMap[key] = this.formData.length;
            this.formData.push({field: key, value: ''});
        }
        return this.formData[this.formKeyIndexMap[key]].value;
    }

    private setValue(key: SubscriptionFormField, value: string | boolean): void {
        if (!this.formConfiguration.hasOwnProperty(key)) {
            return;
        }
        if (!this.formKeyIndexMap.hasOwnProperty(key)) {
            this.formKeyIndexMap[key] = this.formData.length;
            this.formData.push({field: key, value: ''});
        }
        if (typeof value === 'boolean') {
            value = value ? 'true' : 'false';
        }
        this.formData[this.formKeyIndexMap[key]].value = value;
    }

    private isForceRequired(key: SubscriptionFormField): boolean {
        if (key === 'EMAIL') {
            if (this.formKeyIndexMap.hasOwnProperty('READ_MEDIUM') && this.formData[this.formKeyIndexMap.READ_MEDIUM].value === 'DIGITAL') {
                return true;
            }

            if (this.formKeyIndexMap.hasOwnProperty('PERMISSION_EMAIL') && this.formData[this.formKeyIndexMap.PERMISSION_EMAIL].value) {
                return true;
            }
        }

        if (key === 'PHONE') {
            if (this.formKeyIndexMap.hasOwnProperty('PERMISSION_PHONE') && this.formData[this.formKeyIndexMap.PERMISSION_PHONE].value) {
                return true;
            }
        }

        return false;
    }

    private getPlaceholder(item: FormInputConfiguration) {
        if (item.key.startsWith('CUSTOM_FIELD_')) {
            return this.formConfiguration[item.key].customProperties.caption;
        }
        return item.placeholder;
    }

}
